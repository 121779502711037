<template lang="pug">
  .machines-analytics-report-show
    b-row(cols=1)
      b-col.mb-4.mb-xl-5
        panel-machine(
          namespace='machines/analytics/report'
          :data='filter'
          height='80px'
          group='machines-analytics'
          :connect='false'
          :animation='false')
   
    b-row(cols=1)
      b-col.mb-4.mb-xl-5
        panel-batteries(
          namespace='machines/analytics/report'
          :data='filter'
          group='machines-analytics'
          :connect='false'
          :animation='false')

    b-row(
      v-if='hasBusData'
      cols=1)
      b-col.mb-4.mb-xl-5
        panel-operating-hours(
          namespace='machines/analytics/report'
          :data='filter'
          group='machines-analytics'
          :connect='false'
          :animation='false')
    
    b-row(
      v-if='hasBusData'
      cols=1)
      b-col.mb-4.mb-xl-5
        panel-tilt(
          namespace='machines/analytics/report'
          :data='filter'
          group='machines-analytics'
          :connect='false'
          :animation='false')

    b-row(cols=1)
      b-col.mb-4.mb-xl-5
        panel-temperatures(
          namespace='machines/analytics/report'
          :data='filter'
          group='machines-analytics'
          :connect='false'
          :animation='false')

    b-row(
      v-if='hasBusData'
      :style='styleBus'
      cols=1)
      b-col.mb-4.mb-xl-5
        panel-motors-voltage(
          namespace='machines/analytics/report'
          :data='filter'
          group='machines-analytics'
          :connect='false'
          :animation='false')

    b-row(
      v-if='hasBusData'
      cols=1)
      b-col.mb-4.mb-xl-5
        panel-motors-current(
          namespace='machines/analytics/report'
          :data='filter'
          group='machines-analytics'
          :connect='false'
          :animation='false')

    b-row(
      v-if='hasBusData === false'
      cols=1)
      b-col.mb-4.mb-xl-5
        panel-motors-percentage(
          namespace='machines/analytics/report'
          :data='filter'
          group='machines-analytics'
          :connect='false'
          :animation='false')

    b-row(
      :style='styleNoBus'
      cols=1)
      b-col
        panel-map(
          namespace='machines/analytics/report'
          style-class='wc-map-max-height'
          :data='filter')
</template>

<script>
import PanelBatteries from '@components/layouts/shared/machines/analytics/PanelBatteries'
import PanelMachine from '@components/layouts/shared/machines/analytics/PanelMachine'
import PanelMap from '@components/layouts/shared/machines/analytics/PanelMap'
import PanelMotorsPercentage from '@components/layouts/shared/machines/analytics/PanelMotorsPercentage'
import PanelMotorsVoltage from '@components/layouts/shared/machines/analytics/PanelMotorsVoltage'
import PanelMotorsCurrent from '@components/layouts/shared/machines/analytics/PanelMotorsCurrent'
import PanelTemperatures from '@components/layouts/shared/machines/analytics/PanelTemperatures'
import PanelOperatingHours from '@components/layouts/shared/machines/analytics/PanelOperatingHours'
import PanelTilt from '@components/layouts/shared/machines/analytics/PanelTilt'

export default {
  name: 'machines-analytics-report-show',
  components: {
    PanelBatteries,
    PanelMachine,
    PanelMap,
    PanelMotorsPercentage,
    PanelMotorsVoltage,
    PanelMotorsCurrent,
    PanelTemperatures,
    PanelOperatingHours,
    PanelTilt,
  },
  computed: {
    hasBusData() {
      return this.$getDeep(this.$parent.$parent.$refs, 'reportPanel.panel.data.attributes.bus')
    },
    styleNoBus() {
      return !this.hasBusData ? 'margin-top: 40rem; padding-top: 2rem;' : ''
    },
    styleBus() {
      return this.hasBusData ? 'padding-top: 2rem;' : ''
    },
  },
  data() {
    return {
      machine_id: this.$route.params.machine_id,
      filter: {
        from: this.$route.query.from,
        to: this.$route.query.to,
      },
    }
  },
}
</script>
